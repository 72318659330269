<template>
  <v-form
    id="addStoreForm"
    ref="formAddStore"
    v-model="valid"
    lazy-validation
    autocomplete="off"
    @submit.prevent=""
  >
    <v-container class="py-0">
      <v-row class="mb-5">
        <v-col cols="12">
          <h2 class="align-self-end page-title">
            {{ $t(`settings.title.${mode}`) }}
          </h2>
        </v-col>
        <v-col v-if="authEsa">
          <span>{{ orgName }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="7" md="5" lg="4">
          <v-text-field
            id="storeName"
            v-model="model.name"
            :label="$t('label.name')"
            :rules="rules.name"
            :disabled="loading"
            color="privat"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <BaseSelectAddress
          v-if="pageMode !== 'edit'"
          :validate="needValidate"
          :loading="loading"
          @getAddrId="getAddressId"
          @clearAddrId="clearAddrId"
          @error="errorFromChild"
        />
        <v-col
          cols="12"
          sm="7"
          md="5"
          lg="4"
          class="py-0"
          v-if="pageMode === 'edit'"
        >
          <v-text-field
            v-model="model.address"
            :label="$t('label.address')"
            :disabled="pageMode === 'edit'"
            color="privat"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="7" md="5" lg="4">
          <BaseSelectSupport
            :supports="supports"
            :label="`support`"
            :loading="loading"
            :validate="needValidate"
            @setSupport="setSupport"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="7"
          md="5"
          lg="4"
          class="d-flex justify-space-between flex-column flex-sm-row"
        >
          <v-btn
            id="cancel"
            class="mb-4 mb-sm-0"
            color="blue-grey lighten-5"
            elevation="1"
            :disabled="loading"
            @click.native="$router.go(-1)"
          >
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn
            id="addStore"
            color="privat"
            elevation="1"
            :disabled="loading"
            :loading="loading"
            @click.native="sendStore"
          >
            <span class="white--text" v-if="pageMode !== 'edit'">
              {{ $t("action.add") }}
            </span>
            <span class="white--text" v-else>
              {{ $t("action.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import {
  interactionDataAddress,
  searchManager
} from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import BaseSelectAddress from "@/components/BaseSelectAddress";
import BaseSelectSupport from "@/components/BaseSelectSupport";
import CONST from "@/utils/constants";
import changeTextMultiselect from "@/utils/changeTextMultiselect";

export default {
  name: "SettingsStore",
  mixins: [showSnack],
  props: {
    options: Object,
    store: Object,
    company: Object,
    loading: Boolean
  },
  components: {
    BaseSelectAddress,
    BaseSelectSupport
  },
  data() {
    return {
      valid: true,
      model: {
        orgId: null,
        name: null,
        managerLdap: null,
        address: null,
        addressId: null
      },
      mode: "store/add",
      allStores: [],
      supports: [],
      search: null,
      isLoading: false,
      isTouched: false,
      support: null,
      needValidate: false
    };
  },
  computed: {
    ...mapGetters(["getStores", "getPointOfEntry", "getRegions"]),
    isInvalid() {
      return this.isTouched && !this.support;
    },
    rules() {
      return {
        name: [
          v => !!v || this.$i18n.t("rules.required"),
          v => CONST.MIN_MAX_NAME.test(v) || this.$i18n.t("rules.MIN_MAX_NAME")
        ],
        address: [v => !!v || this.$i18n.t("rules.required")],
        support: [v => !!v || this.$i18n.t("placeholder.support")]
      };
    },
    pageMode() {
      return this.$route.params.id;
    },
    editStoreId() {
      return this.$route.query.id;
    },
    getOrgId() {
      return this.$route.query.orgId;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    orgName() {
      let orgName = this.$i18n.t("settings.title.manageCompany");

      if (this.authEsa) {
        orgName = this.company?.name || this.selectedCompany?.name;
      }

      return orgName;
    },
    selectedCompany() {
      return this.options.companies.find(
        el => el.id === +this.$route.query.orgId
      );
    }
  },
  methods: {
    findManager(val) {
      if (this.isLoading) return;

      if (val?.length >= 5) {
        this.isLoading = true;
        searchManager(val)
          .then(res => {
            this.supports = [];
            this.supports = res.data.map(el => {
              return {
                ...el,
                label: `${el.surname} ${el.firstName} ${el.patronymic}`
              };
            });
          })
          .catch(err => {
            let errorCode = errorHandler(err, "getData");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch searchManager");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    sendStore() {
      this.needValidate = !this.needValidate;
      if (
        this.$refs.formAddStore.validate() &&
        this.model.addressId &&
        this.model.managerLdap
      ) {
        if (this.pageMode !== "edit") {
          this.$emit("event", ["addStore", this.model]);
        } else {
          this.$emit("event", ["updateStore", this.model]);
        }
      }
    },
    setStore() {
      if (!this.store) {
        this.$store
          .dispatch("getStoresCompany", this.editStoreId)
          .then(() => {
            let data = this.getStores;
            let foundStore = data.find(el => el.id === +this.editStoreId);

            this.setModelAndSupport(foundStore);
          })
          .catch(() => {
            this.$router.go(-1);
          });
      } else {
        this.setModelAndSupport(this.store);
      }
    },
    setModelAndSupport(store) {
      let arrName = store.managerName.split(" ");

      this.model = {
        id: store.id,
        orgId: store.orgId,
        name: store.name,
        managerLdap: store.managerLdap,
        address: store.address,
        addressId: store.addressId
      };
      this.support = {
        surname: arrName[0],
        firstName: arrName[1],
        patronymic: arrName[2],
        id: store.managerLdap,
        label: `${arrName[0]} ${arrName[1]} ${arrName[2]}`,
        isTouched: false
      };
      this.supports.push(this.support);
    },
    createUrlAddress(modelAd) {
      let corpus = modelAd.corpus ? `&corpus=${modelAd.corpus}` : "";
      let flat = modelAd.flat ? `&flat=${modelAd.flat}` : "";
      let idStreet = modelAd.street.code;
      let building = modelAd.building.desc;

      return `esa/addr/updateWCheck?id=${idStreet}&house=${building}${corpus}${flat}`;
    },
    getAddressId(modelAd) {
      let url = this.createUrlAddress(modelAd);

      this.loading = true;
      interactionDataAddress(url, "POST")
        .then(res => {
          let data = res.data;

          this.model.addressId = data;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getAddressId");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearAddrId() {
      this.model.addressId = null;
    },
    errorFromChild(error) {
      this.showSnack("error", error);
    },
    setSupport(val) {
      this.model.managerLdap = val.id;
    }
  },
  mounted() {
    if (this.pageMode === "edit") {
      this.mode = "store/edit";
      if (this.authEsa) {
        this.setStore();
      }
    } else {
      this.model = {
        orgId: +this.getOrgId,
        name: null,
        managerLdap: null,
        addressId: null
      };
    }
    this.timeoutChangeText = setTimeout(() => {
      changeTextMultiselect.call(this);
    }, 100);
  },
  beforeDestroy() {
    clearTimeout(this.timeoutChangeText);
  }
};
</script>

<style lang="scss">
#addStoreForm {
  width: 100%;
  .container {
    max-width: 100%;
  }
  .v-btn {
    height: 39px;
  }
  [class*="col"] {
    padding: 2px 12px;
  }
  #cancel {
    width: 37%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  #addStore {
    width: 57%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
</style>
